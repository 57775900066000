/* eslint-disable @backstage/no-relative-monorepo-imports */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ItemCardHeader } from '@backstage/core-components';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@mui/material/Divider';
import '../card-component.css';

import {
  sendRequestStatus,
  ANVILOPS_REQUEST_STATES,
  Request,
} from '../../../internal';
import { State } from '../../../../../../packages/app/src/store/types';
import { Box, Typography } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';

export const ApprovalsCard = ({
  requestObject,
}: {
  requestObject: Request;
}) => {
  const accessToken = useSelector((state: State) => state.accessToken);

  const [approvalResponse, setApprovalResponse] =
    useState<ANVILOPS_REQUEST_STATES>(ANVILOPS_REQUEST_STATES.PENDING);
  const [isApprovalLoading, setIsApprovalLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isResponseReady, setIsResponseReady] = useState(false);
  const [isCardVisible, setIsCardVisible] = useState(true);

  const ApprovalResponse = () => {
    if (approvalResponse === ANVILOPS_REQUEST_STATES.APPROVED) {
      return (
        <Alert severity="success">
          <strong>Request Approved!</strong>
        </Alert>
      );
    } else if (approvalResponse === ANVILOPS_REQUEST_STATES.REJECTED) {
      return (
        <Alert severity="error">
          <strong>Request Rejected!</strong>
        </Alert>
      );
    }

    return (
      <Alert severity="warning">
        <strong>Invalid Response!</strong>
      </Alert>
    );
  };

  const handleApproval = async (approvalStatus: ANVILOPS_REQUEST_STATES) => {
    setIsResponseReady(false);

    if (approvalStatus === ANVILOPS_REQUEST_STATES.APPROVED) {
      setIsApprovalLoading(true);
      setIsRejectLoading(false);
    } else if (approvalStatus === ANVILOPS_REQUEST_STATES.REJECTED) {
      setIsApprovalLoading(false);
      setIsRejectLoading(true);
    } else {
      setIsApprovalLoading(false);
      setIsRejectLoading(false);
    }

    const sendRequestResponse = await sendRequestStatus(
      {
        requestId: requestObject.requestId ?? '',
        requestStatus: approvalStatus,
      },
      accessToken,
    );

    if (
      sendRequestResponse.status === 200 &&
      sendRequestResponse.data.requestStatus === approvalStatus
    ) {
      setApprovalResponse(sendRequestResponse.data.requestStatus);
      setIsResponseReady(true);
    }

    // Hide the card after a certain duration (e.g., 3 seconds)
    setTimeout(() => {
      setIsCardVisible(false);
    }, 3000);
  };

  const RequestItem = () => {
    return (
      <FormControl fullWidth margin="normal" required>
        <Stack spacing={2} direction="row">
          <Button
            size="large"
            fullWidth
            variant="outlined"
            disabled={isRejectLoading && !isResponseReady}
            onClick={() => handleApproval(ANVILOPS_REQUEST_STATES.REJECTED)}
          >
            {isRejectLoading && !isResponseReady ? 'Rejecting…' : 'Reject'}
          </Button>
          <Button
            size="large"
            fullWidth
            variant="contained"
            disabled={isApprovalLoading && !isResponseReady}
            onClick={() => handleApproval(ANVILOPS_REQUEST_STATES.APPROVED)}
          >
            {isApprovalLoading && !isResponseReady ? 'Approving…' : 'Approve'}
          </Button>
        </Stack>
      </FormControl>
    );
  };

  return (
    <>
      {isCardVisible && (
        <Card key={requestObject.requestId} className="card">
          <CardMedia>
            <ItemCardHeader
              title={requestObject.requestType}
              subtitle={`Request Id: ${requestObject.requestId}`}
            />
          </CardMedia>
          <CardContent
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >
            <Box height="300px">
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: requestObject.description,
                  }}
                />
              </Typography>
            </Box>
          </CardContent>
          <Divider variant="middle" />
          <Box
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingBottom: '10px',
              paddingTop: '10px',
            }}
          >
            {!isResponseReady && <RequestItem />}
            {/* Display the approval status */}
            {isResponseReady && <ApprovalResponse />}
          </Box>
        </Card>
      )}
    </>
  );
};
